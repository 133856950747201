<template>
	<div>
		<div class="header filter">
			<!-- 해더 -->
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{ program.title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
			<!-- //해더 -->
			<!-- 멤버검색 -->
			<div class="serch_top">
				<div class="top top_wh">
					<div class="header_search">
						<input
							v-model="item_search.search_value"
							type="text" :placeholder="$language.common.search_member"
							maxlength="20"
							@keyup.enter="getData(1)"
						>
					</div>
					<div class="header_search_btn">
						<div class="btn_del">
							<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
							<button
								v-if="item_search.search_value"
								class="active"
								@click="item_search.search_value = ''; getData(1)"
							><span class="hide">{{ $language.common.close }}</span></button>
						</div>
						<div class="util">
							<button
								@click="getData(1)"
								class="img_button" type="submit" name="click"
							><span class="hide">{{ $language.common.search }}</span></button>
						</div>
					</div>
				</div>
			</div>
			<!-- //멤버검색 -->
		</div>
		<!-- //고정해더 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-120" ref="post_list_item_view">
			<!-- 멤버초대 -->
			<div class="thumbnail_wrap">
				<div class="container">
					<div class="row">
						<ul class="thumbnail_list ct_invite">
							<h3 class="hide">{{ $language.common.search_member_result }}</h3>
							<template
								v-if="invite_member.length > 0"
							>
							<li
								v-for="(item, index) in invite_member"
								:key="'item_' + index"
								@click="on098(item)"
							>
								<div class="ct_invite_list">
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item.profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url)"
												>
												<img
													v-else
													:src="require('@/assets/image/@nophoto.png')" :alt="$language.cartel.recommend_cartel"
												/>
											</div>
											<div class="text_area">
												<strong>{{ item.nickname }}</strong>
												<p class="textzone textzone">
													{{  item.cartl_member_grade_name }}
												</p>
											</div>
										</div>
									</div>
								</div>
							</li>
							</template>

							<div
								v-else
								class="small_thumbnail_flex"
							><!-- 해당 페이지 높이값 지정 -->
								<div class="none_top character3_none">
									<div class="text_guide">
										<span class="cont_none">{{ $language.common.no_search_data }}</span>
									</div>
								</div>
							</div>
						</ul>
					</div>
				</div>
			</div>
			<!-- //멤버초대 -->
		</div>

		<mafia098
			v-if="is_098"
			:cartel="item_cartel"
			:user="user"
			:friend="item_user"

			@cancel="is_098 = false"
			style="position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 9999; background-color: white"
		></mafia098>

		<ScrollPagination
			v-if="$refs.post_list_item_view"
			:body="$refs.post_list_item_view"
			:item_search="item_search"
			:max="max"
			:is_add="is_add"

			@change="getData"
		></ScrollPagination>
	</div>
</template>

<script>
import mafia098 from "@/view/Cartel/mafia098";
import ScrollPagination from "@/components/ScrollPagination";
export default {
	name: 'mafia082'
	, props: ['user']
	, components: {ScrollPagination, mafia098},
	data: function(){
		return {
			program: {
				name: this.$language.common.view_member
				, title: this.$language.common.view_member
				, type: 'cartel_sub'
				, not_header: true
				, not_footer: true
			}
			, items_member: []
			, item_cartel: {}
			, item_search: this.$storage.init({
				search_value: ''
				, page_number: this.$language.base.page_number
				, pagerecnum: this.$language.base.pagerecnum
			})
			, is_098 : false
			, item_user: {}
			, is_add: true
			, max: false
		}
	}
	, computed: {
		invite_member: function(){
			let self = this
			console.log(self.item_cartel.user)
			return this.items_member.filter(function(item_member){

				return item_member
			})
		}
	}
	, methods: {
		getData: async function(page = 1){
			try{
				this.item_search.page_number = page
				this.is_add = false
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_member_search
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, srchtext: this.item_search.search_value
						, page_number: this.item_search.page_number
						, pagerecnum: this.item_search.pagerecnum
					}
					, type: true
				})

				if(result.success){
					if(this.item_search.page_number == 1){
						this.items_member = result.data.cartl_member_list
					}else{
						this.items_member = this.items_member.concat(result.data.cartl_member_list)
					}
					if(result.data.cartl_member_list.length > 0){
						this.max =false
					}else{
						this.max = true
					}

					this.$storage.setQuery(this.item_search)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.is_add = true
				this.$bus.$emit('on', false)
			}
		}
		,getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postInvite: async function(member_id){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_invite
					, data: {
						cartel_id: this.item_cartel.id
						, member_id: member_id
					}
					, name: 'postInvite'
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: this.$language.common.succeedInvite})
					await this.getCartel()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, on098: function(user){
			console.log('user', user)
			this.is_098 = true
			this.item_user = user
		}
	}
	,async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getData()
	}

}
</script>